.sdm-app-card {
  $self: &;

  background-color: #ffffff;
  padding: 0.75rem 0.75rem 1rem 0.75rem;

  &--medium {
    padding: 1.5rem 2.5rem 1.5rem 3rem;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;

    #{$self}--medium & {
      flex-wrap: nowrap;
      align-items: flex-start;
    }
  }

  &__thumbnail-wrapper {
    padding: 0.75rem;
    margin-right: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.75rem;
    background-image: linear-gradient(to bottom, #fdfdfd, #f6f6f6);

    #{$self}--medium & {
      margin-bottom: 0;
    }
  }

  &__thumbnail-wrapper,
  &__caret-wrapper {
    width: 5.75rem;
    height: 4.375rem;
  }

  &__thumbnail {
    max-width: 100%;
    max-height: 100%;
  }

  &__wrapper {
    #{$self}--medium & {
      flex: 0 0 66.66%;
    }
  }

  &__general-info {
    display: flex;
    justify-content: flex-end;

    #{$self}--medium & {
      justify-content: space-between;
    }
  }

  &__middle-section {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    #{$self}--medium & {
      justify-content: flex-start;
      width: 20rem;
    }
  }

  &__students-with-badge {
    display: flex;
    align-items: center;

    #{$self}--medium & {
      margin-right: 3rem;
    }
  }

  &__controls {
    flex: 0 1 100%;

    #{$self}--medium & {
      flex: 0 1 auto;
    }

    &--archived {
      padding: 0;
    }
  }

  &__students-icon {
    height: 1.5625rem;
    width: 1.5625rem;
    margin-right: 0.5rem;
  }

  &__students-badge {
    margin-right: 1rem;

    #{$self}--medium & {
      margin-right: 0;
    }

    &--success {
      color: #ffffff;
      background-color: #59b259;
    }
  }

  &__title {
    font-size: 1.125rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #333333;
    margin-bottom: 0.5rem;
  }

  &__buttons {
    display: flex;
    align-items: center;
    margin-left: -0.125rem;
  }

  &__buttons-separator {
    height: 0.3125rem;
    width: 0.3125rem;
    margin: 0 0.5rem;
    border-radius: 50%;
    background-color: #b5b5b5;
    margin-top: -0.25rem;
  }

  &__button {
    font-size: 0.8125rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    color: #116eee;

    &[disabled] {
      pointer-events: initial;
      cursor: not-allowed;
    }
  }

  &__caret-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 2rem;

    #{$self}--medium & {
      margin-right: 0;
    }
  }

  &__caret {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.125rem;
    width: 2rem;
    height: 2rem;
    transition: box-shadow 0.2s;

    &:focus {
      box-shadow: 0 0 0 0.125rem #116eee;
    }

    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &__caret-icon {
    transition: transform 0.2s;

    #{$self}--expanded & {
      transform: rotate(180deg);
    }
  }

  &__students-wrapper {
    margin-top: 2rem;
    flex: 0 1 100%;

    #{$self}--medium & {
      flex: 0 1 auto;
    }
  }

  &__students-title {
    font-size: 0.6875rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: 0.09375rem;
    color: #333333;
    border-bottom: 1px solid #b5b5b5;
    padding-bottom: 0.75rem;
  }

  &__students {
    padding: 0;
    margin: 0;
    list-style: none;
    overflow-y: auto;
    margin-bottom: 0.5rem;
    padding-right: 0.5rem;
    max-height: 19.125rem;
    border-bottom: 1px solid #b5b5b5;
  }

  &__student {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0;
    border-bottom: 1px solid #b5b5b5;

    &:last-child {
      border-bottom: none;
    }
  }
  &__student-name {
    max-width: 14rem;
    overflow-wrap: break-word;
  }
}

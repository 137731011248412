@import '~@scholastic/volume-core/src/variables';

@mixin input-placeholder {
  font-family: $font-family-sans-serif;
  color: $gray-600;
  font-size: 1rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal !important;
  line-height: 1.19;
  letter-spacing: normal !important;
}

.sdm-student-form {
  $self: &;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 0;

  &--medium {
    flex-wrap: nowrap;
    margin: 0 -0.5rem 0.375rem;
  }

  &__control {
    position: relative;
    margin-top: 1.5625rem;
    margin-bottom: 0.375rem;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      flex: 0 0 auto;
    }

    &:nth-child(1),
    &:nth-child(2) {
      width: 100%;
    }

    &:nth-child(3),
    &:nth-child(4) {
      width: 50%;
    }

    &:nth-child(3) {
      padding-right: 0.5rem;
    }

    &:nth-child(4) {
      padding-left: 0.5rem;
    }

    #{$self}--medium & {
      margin-bottom: 0;
      padding: 0 0.5rem;
    }

    #{$self}--medium &:nth-child(1),
    #{$self}--medium &:nth-child(2) {
      width: 30%;
    }

    #{$self}--medium &:nth-child(3),
    #{$self}--medium &:nth-child(4) {
      width: 20%;
    }

    #{$self}--medium &:nth-child(4) {
      margin-right: 0;
    }

    #{$self}--large &:nth-child(1),
    #{$self}--large &:nth-child(2) {
      width: 33.33%;
    }

    #{$self}--large &:nth-child(3),
    #{$self}--large &:nth-child(4) {
      width: 16.665%;
    }
  }

  &__input-label {
    font-family: $font-family-sans-serif;
    color: $gray-900;
    font-size: 0.8125rem !important; // TODO: Remove the important flag after volume form cleanup
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15 !important; // TODO: Remove the important flag after volume form cleanup
    letter-spacing: normal;
  }

  &__input {
    width: 100%;

    &::placeholder {
      @include input-placeholder;
    }
  }

  &__input-label-text {
    position: absolute;
    top: -0.5rem;
    left: 0;
    transform: translateY(-100%);
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 0.8125rem;
    font-weight: 500;
    line-height: 1.15;
    color: #333333;
    margin-bottom: 0.5rem;
  }

  &__form-feedback {
    width: 100%;
  }
}

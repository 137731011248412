.sdm-easy-login-switch-section {
  $self: &;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;

  &--medium {
    padding: 1.5rem 3rem;
  }

  &__title {
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #333333;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.19;
    margin-right: 0.75rem;

    #{$self}--medium & {
      font-size: 1.3125rem;
      line-height: 1.14;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__label {
    font-size: 0.8125rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    margin-left: 0.75rem;
    color: #333333;
    width: 1.25rem;
    text-align: right;

    &--on {
      color: #59b259;
    }
  }
}

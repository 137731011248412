// core overrides

$svgs---enabled: false;
$asset-base-path: '~@scholastic/volume-core/build/assets';

$body-bg: #f4f4f4;

@import '~@scholastic/volume-core/src/variables';

// components

$program-card-list__header--extra-small-down---margin-top: 2rem;
$program-card-list__header--small-up---margin-top: 4rem;

$redeem-access-modal--small---margin-left: 15.625rem;
$redeem-access-modal--medium-up---margin-left: 25rem;

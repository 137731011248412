.sdm-class-details-apps {
  $self: &;

  box-shadow: 0 0.3125rem 0.9375rem 0 rgba(0, 0, 0, 0.1);

  &__app-card {
    border-bottom: 1px solid #b5b5b5;

    &:last-child {
      border-bottom: none;
    }
  }
}

.sdm-faq-section {
  $self: &;

  border-top: 1px solid #b5b5b5;
  padding: 2rem 0.75rem;

  &--medium {
    display: flex;
    align-items: flex-start;
    padding: 2rem 2.5rem 2rem 3rem;
  }

  &__title {
    flex: 0 0 12.5rem;
    white-space: nowrap;
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #333333;
    margin-bottom: 1rem;

    #{$self}--medium & {
      margin-right: 1rem;
    }

    #{$self}--large & {
      margin-right: 2rem;
    }

    #{$self}--extra-large & {
      margin-right: 5.125rem;
    }
  }

  &__title-icon {
    margin-right: 0.5rem;
  }

  &__collapsible-list {
    flex: 1 1 100%;

    #{$self}--medium & {
      margin-top: -0.75rem;
    }
  }
}

.sdm-custom-bullet-list {
  $self: &;

  list-style: none;
  padding: 0;
  margin-left: 1.375rem;

  &__item {
    display: flex;
    font-size: 1rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #333333;
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item::before {
    content: url(../../../../assets/img/icon-success.svg);
    margin-top: 0.15rem;
    margin-left: -1.25rem;
    margin-right: 0.625rem;
    font-size: 1rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #333333;
  }
}

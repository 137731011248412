@import '~@scholastic/volume-core/src/mixins';
@import '~@scholastic/volume-core/src/smart/page-header/page-header.module';
@import '~@scholastic/volume-core/src/variables';
@import '../../styles/functions';
@import '../../styles/variables';

.sdm-teacher-programs {
  $self: &;

  display: block;
  width: 100%;

  &__classroom-magazines {
    .vol-section-header {
      margin-top: 2rem;
    }

    .vol-section-header--medium {
      margin-top: 4rem;
    }
  }

  &__empty-splash {
    margin-top: 2rem;
  }

  &__redeem-access-modal {
    .vol-modal__dialog {
      height: 100%;
    }
  }
}

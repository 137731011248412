@import '~@scholastic/volume-core/src/mixins';
@import '../../../../styles/functions';
@import '../../../../styles/variables';

.sdm-share-devices-section {
  $self: &;

  background-color: #ffffff;
  border-top: 1px solid #b5b5b5;
  padding: 1.5rem 0.75rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  flex: 1 1 100%;

  &--medium {
    padding: 2rem 2.5rem 2rem 3rem;
    flex-wrap: nowrap;
    justify-content: space-between;
    flex: 0 1 auto;
  }

  &__title {
    font-size: 1.125rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #333333;
    text-align: center;
    margin-bottom: 1rem;
    flex: 1 1 100%;

    #{$self}--medium & {
      display: flex;
      align-items: center;
      text-align: left;
      margin-bottom: 0;
    }
  }

  &__title-text,
  &__help {
    vertical-align: middle;
  }

  &__help {
    padding: 0;
    background: none;
    border: none;
    display: inline-flex;
    border-radius: 50%;
    transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    margin-left: 0.5rem;

    &:hover:not(:active):not(&--active) {
      background-color: #ededed;
    }

    &:focus:not(:active):not(&--active) {
      box-shadow: 0 0 0 0.125rem $blue inset;
      outline: none;
    }

    &:active,
    &--active {
      background-color: #e0e0e0;
      outline: none;
    }
  }

  &__help-icon {
    width: 1.125rem;
    height: 1.125rem;
  }

  &__buttons-wrapper {
    display: flex;

    .vol-icon-button {
      position: relative;
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__arrow-wrapper {
    border-top: 0.25rem solid #ffffff;
    overflow: hidden;
    width: 1.75rem;
    height: 1.25rem;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 2.625rem);

    #{$self}--medium & {
      transform: translate(-50%, 3.125rem);
    }
  }

  &__arrow {
    background: white;
    width: 1.25rem;
    height: 1.25rem;
    border: solid 1px #b5b5b5;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &__popover {
    max-width: 20.5rem;

    .vol-popover__body {
      font-size: 0.6875rem;
      padding: 1rem 1.25rem;
    }
  }

  &__popover-text {
    margin-bottom: 0.625rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@import 'functions'; // import our own functions that extend volume's
@import 'variables'; // import our own theme variables that extend volume's
@import '~@scholastic/volume-core/src/mixins';

@import '~@scholastic/volume-core/src/fonts';
@import '~@scholastic/volume-core/src/root';

/*
 * Since SDM already includes normalize styles, lets not reboot. This should be included when the UX project is complete
 * and SDM is no longer using Bootstrap 3 alongside volume-core
 */
//@import '~@scholastic/volume-core/src/reboot';

/*
 * We are shimming in font smoothing and wrapping type as it conflicts with Bootstrap 3
 */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

*[class^='vol-'] {
  @import '~@scholastic/volume-core/src/type';
}

*[class^='vol-'],
*[class^='sdm-'] {
  font-family: $font-family-base;
}

//@import '~@scholastic/volume-core/src/images';
//@import '~@scholastic/volume-core/src/code';
@import '~@scholastic/volume-core/src/container';
//@import '~@scholastic/volume-core/src/grid';
//@import '~@scholastic/volume-core/src/tables';
@import '~@scholastic/volume-core/src/forms';
@import '~@scholastic/volume-core/src/buttons';
@import '~@scholastic/volume-core/src/transitions';
//@import '~@scholastic/volume-core/src/button-group';
//@import '~@scholastic/volume-core/src/input-group';
//@import '~@scholastic/volume-core/src/custom-forms';
@import '~@scholastic/volume-core/src/nav';
@import '~@scholastic/volume-core/src/navbar';
@import '~@scholastic/volume-core/src/checkbox';
//@import '~@scholastic/volume-core/src/breadcrumb';
//@import '~@scholastic/volume-core/src/pagination';
//@import '~@scholastic/volume-core/src/badge';
@import '~@scholastic/volume-core/src/jumbotron';
//@import '~@scholastic/volume-core/src/alert';
//@import '~@scholastic/volume-core/src/progress';
//@import '~@scholastic/volume-core/src/media';
//@import '~@scholastic/volume-core/src/list-group';
@import '~@scholastic/volume-core/src/close';
//@import '~@scholastic/volume-core/src/toasts';
@import '~@scholastic/volume-core/src/modal';
@import '~@scholastic/volume-core/src/tooltip';
@import '~@scholastic/volume-core/src/popover';
@import '~@scholastic/volume-core/src/metric';
//@import '~@scholastic/volume-core/src/carousel';
@import '~@scholastic/volume-core/src/utilities';
@import '~@scholastic/volume-core/src/pure/avatar/avatar';
@import '~@scholastic/volume-core/src/pure/fab/fab';
@import '~@scholastic/volume-core/src/pure/fab-menu/fab-menu';

@import '~@scholastic/volume-core/src/pure';
@import '~@scholastic/volume-core/src/smart';

@import 'overrides';

.sdm-privacy-and-security {
  $self: &;

  flex: 0 0 auto;
  border: solid 1px #b5b5b5;
  width: 100%;
  padding: 1rem 1.25rem;

  &--medium {
    width: 17.5rem;
    padding: 1rem 1.25rem 1.25rem 1.25rem;
  }

  &__title {
    font-size: 1rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.19;
    letter-spacing: normal;
    color: #333333;
    margin-bottom: 0.75rem;
    margin-right: 0.75rem;
  }

  &__title-button {
    border: none;
    background: none;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    #{$self}--is-open & {
      margin-bottom: 0.75rem;
    }
  }

  &__title-button-caret {
    margin-right: -0.5rem;
    width: 1.375rem;
    height: 1.375rem;
    transition: transform 0.2s;

    #{$self}--is-open & {
      transform: rotate(180deg);
    }
  }

  &__logo {
    margin-right: 1.125rem;
    margin-left: 0.25rem;
  }

  &__text {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #333333;
  }

  &__link {
    color: #116eee;
  }
}

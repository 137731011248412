@import '~@scholastic/volume-core/src/mixins';
@import '../../../styles/functions';
@import '../../../styles/variables';

.sdm-step-enter-code {
  $self: &;

  flex: 1;
  display: flex;
  justify-content: center;
  padding: 1rem 1.25rem;

  &--medium {
    justify-content: flex-end;
    padding: 1rem 2.5rem;
  }

  &__image-wrapper {
    display: block;

    #{$self}--medium & {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      margin-left: 2.5rem;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__enter-code-image {
    &--full {
      #{$self}--medium & {
        margin: 0;
        display: block;
        width: 13.125rem;
      }

      #{$self}--large & {
        width: 22.5rem;
      }
    }

    &--inline {
      margin: 1rem auto;
      width: 14.4375rem;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 1rem;

    #{$self}--medium & {
      display: block;
      flex: 0 1 auto;
      padding-top: 3rem;
      padding-right: 3rem;
      width: 18.75rem;
    }

    #{$self}--extra-large & {
      padding-right: 0;
      margin-right: 13.75rem;
    }
  }

  &__title {
    &#{&} {
      font-family: MuseoSans, sans-serif;
      font-size: 2rem;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.09;
      letter-spacing: normal;
      color: #333333;
      margin: 0;
      text-align: center;

      #{$self}--medium & {
        margin: 0 0 3.0625rem 0;
        text-align: left;
      }
    }
  }

  &__access-code-form {
    display: flex;
    flex-direction: column;
    flex: 1;

    #{$self}--medium & {
      flex: 0 1 auto;
    }
  }

  &__form-group {
    margin-bottom: 2rem;
  }

  &__label-text-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 0.625rem;
  }

  &__input {
    &::placeholder {
      font-family: MuseoSans, sans-serif;
      font-size: 1rem;
      font-weight: 300;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      color: #666666;
    }
  }

  &__feedback {
    &#{&}#{&} {
      display: flex;
    }
  }

  &__access-code-help {
    padding: 0;
    background: none;
    border: none;
    display: inline-flex;
    border-radius: 50%;
    transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    margin-left: 0.5rem;

    &:hover:not(:active):not(&--active) {
      background-color: #ededed;
    }

    &:focus:not(:active):not(&--active) {
      box-shadow: 0 0 0 0.125rem $blue inset;
      outline: none;
    }

    &:active,
    &--active {
      background-color: #e0e0e0;
      outline: none;
    }
  }

  &__access-code-help-popover {
    max-width: 20.5rem;

    .vol-popover__body {
      font-size: 0.6875rem;
      padding: 1rem 1.25rem;
    }
  }

  &__submit-button {
    margin-top: auto;

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.6;
    }

    #{$self}--medium & {
      margin-top: 0;
      width: 7.5rem;
    }
  }
}

.sdm-class-details-add-student-form {
  $self: &;

  &__submit-button {
    width: 100%;

    #{$self}--medium & {
      width: auto;
    }
  }
}

@import '~@scholastic/volume-core/src/shims/un-bootstrap';
@import 'theme';

body.sdm-body {
  background-color: $body-bg;
  color: #333333;

  .pagecontainer {
    background-color: $body-bg;

    #maincontainer {
      background-color: $body-bg;

      .mainSubContainer {
        margin: 0;

        @media (max-width: 767px) {
          margin: 0 !important;
        }
      }
    }
  }

  .sdm-footer {
    margin-top: 1.5rem;
  }
}

.vol-page-loader {
  padding: 4rem;
}

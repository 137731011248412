.sdm-program-card-list-empty {
  $self: &;

  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__img {
    height: 9.75rem;
    margin-bottom: 1.5rem;
    margin-top: 2rem;
    width: 17.25rem;

    #{$self}--medium & {
      height: 20.25rem;
      width: 36.25rem;
    }
  }

  &__text {
    color: #333333;
    font-size: 1.75rem;
    font-weight: 300;
    line-height: 1.14;
    margin-bottom: 1.5rem;
    text-align: center;

    #{$self}--medium & {
      font-size: 2rem;
      line-height: 1.09;
    }
  }
}
@import '../../styles/functions';
@import '../../styles/variables';
@import '~@scholastic/volume-core/src/mixins';
@import '~@scholastic/volume-core/src/pure/control';
@import '~@scholastic/volume-core/src/pure/input';

@mixin select($root, $marginBottom: true) {
  &#{&}#{&} {
    max-width: 100%;
    flex: 0 1 100%;

    @if ($marginBottom) {
      margin-bottom: 1rem;
    }
  }

  #{$root}--medium &#{&}#{&} {
    flex: 0 1 auto;
    margin-bottom: 0;
  }
}

@mixin input-placeholder {
  font-family: $font-family-sans-serif;
  color: $gray-600;
  font-size: 1rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal !important;
  line-height: 1.19;
  letter-spacing: normal !important;
}

.sdm-teacher-class-create {
  $self: &;

  &__banner {
    height: 6.625rem;
  }

  &__dialog {
    box-shadow: 0 0.3125rem 0.9375rem 0 rgba(0, 0, 0, 0.1);
    margin-top: -3.625rem;

    // Overrides
    .vol-modal__header {
      padding: 1rem 1rem 1.5rem;
    }

    .vol-modal__title {
      margin: 0;
      font-size: 1.3125rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
      color: #333333;
    }

    .vol-modal__body {
      padding: 0 1rem 1.5rem;
    }
  }

  #{$self}--medium &__dialog .vol-modal__header {
    padding: 1.5rem 2rem;
  }

  #{$self}--medium &__dialog .vol-modal__title {
    font-size: 1.5rem;
  }

  #{$self}--medium &__dialog .vol-modal__body {
    padding: 0 2rem 1.5rem;
  }

  &__modal-header {
    align-items: center;
  }

  &__close-button {
    &#{&} {
      opacity: 1;
      width: 2rem;
      height: 2rem;
      border: 0.125rem solid transparent;
    }

    &#{&}:hover {
      opacity: 0.5;
    }

    &#{&}:focus {
      border: 0.125rem solid #116eee;
    }
  }

  &__close-icon {
    width: 100%;
    height: 100%;
  }

  &__section {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1.125rem;

    #{$self}--medium & {
      flex-wrap: nowrap;
    }

    &--no-margin {
      margin-bottom: 0;
    }

    &--margin-small {
      margin-bottom: 0.625rem;
    }
  }

  &__building {
    @include select($self);
  }

  &__calendar {
    @include select($self);
  }

  &__primary-teacher {
    @include select($self, false);
  }

  &__select {
    font-family: $font-family-sans-serif;
    border-left: $border-divider-vertical;
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 0.5rem;
    padding-right: 1rem;

    // Overrides
    .vol-select-list__control {
      padding: 0;
    }

    .vol-select-list__menu {
      width: auto;
      min-width: 11.875rem;
      max-width: 15rem;
    }

    .vol-select-list__option {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  // Overrides
  #{$self}--small &__select .vol-select-list__menu {
    max-width: 17.5rem;
  }

  &__select-label {
    font-family: $font-family-sans-serif;
    color: $gray-900;
    font-size: 0.6875rem !important; // TODO: Remove the important flag after volume form cleanup
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1.09 !important; // TODO: Remove the important flag after volume form cleanup
  }

  &__select-label-input {
    max-width: 100%;
  }

  &__metric {
    border-left: $border-divider-vertical;
    margin: 0;
    padding-right: 1.5rem;
    padding-left: 0.5rem;
  }

  &__metric-heading {
    color: $gray-900;
    font-weight: $headings-font-weight;
    align-items: flex-start;
    justify-content: flex-start;
    height: 0.75rem;
    margin-bottom: 0.25rem;
    font-size: 0.6875rem;
    line-height: 1.09;
    text-align: left;
  }

  &__metric-content {
    color: $gray-900;
    margin: 0;
    align-items: flex-start;
    justify-content: flex-start;
    height: 1.1875rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.19;
  }

  &__class-name {
    &#{&}#{&} {
      flex: 0 1 100%;
      margin-bottom: 1.5rem;
    }

    #{$self}--medium &#{&}#{&} {
      flex: 0 1 50%;
      margin-bottom: 0;
      margin-right: 1.8125rem;
    }
  }

  &__grades-wrapper {
    display: flex;
    flex: 0 1 100%;

    #{$self}--medium & {
      flex: 0 1 50%;
      margin-left: 1.8125rem;
    }

    // Overrides
    .vol-searchable-dropdown__toggle,
    .vol-searchable-dropdown__input {
      height: 3.125rem;
      border-radius: 0.3125rem;
    }
  }

  &__co-teachers {
    width: 100%;

    #{$self}--medium & {
      width: auto;
      flex: 0 1 50%;
      padding-right: 1.8125rem;
    }

    // Overrides
    .vol-person-search__placeholder {
      @include input-placeholder;
    }

    .vol-person-search__input input {
      letter-spacing: initial;
    }

    .vol-person-search__menu-notice {
      text-align: left;
      color: #333333;
      padding: 0.625rem 0.575rem;
    }
  }

  &__add-co-teacher {
    position: relative;
    margin-bottom: 1rem;
    width: calc(100% - 2.375rem);

    #{$self}--medium & {
      width: 100%;
    }

    &--no-remove-button {
      width: auto;
    }

    &--no-margin {
      margin-bottom: 0;
    }
  }

  &__remove-button {
    background: none;
    border: 0.125rem solid transparent;
    border-radius: 50%;
    padding: 0.25rem;
    position: absolute;
    top: 3rem;
    right: -0.5625rem;
    transform: translate(100%, -50%);

    &:focus {
      border: 0.125rem solid #116eee;
    }
  }

  &__remove-icon {
    width: 1.375rem;
    height: 1.375rem;
  }

  &__remove-button:hover &__remove-icon,
  &__remove-button:focus &__remove-icon {
    opacity: 0.6;
  }

  .vol-form__group {
    margin-bottom: 0;
  }

  &__single-grade {
    flex: 1 1 auto;
  }

  &__nickname,
  &__grade--single {
    width: 100%;
  }

  &__grades {
    display: flex;
    flex: 1 1 auto;
  }

  &__grade {
    width: 6.625rem;
    flex: 0 1 50%;
    margin-bottom: 0;

    #{$self}--medium & {
      width: auto;
    }

    .vol-searchable-dropdown__input {
      letter-spacing: initial;
      border: 0.0625rem solid #919191;
      height: 3.125rem;
      border-radius: 0.3125rem;

      &::placeholder {
        @include input-placeholder;
      }
    }
  }

  &__input-label {
    font-family: $font-family-sans-serif;
    color: $gray-900;
    font-size: 0.8125rem !important; // TODO: Remove the important flag after volume form cleanup
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15 !important; // TODO: Remove the important flag after volume form cleanup
    letter-spacing: normal;
  }

  &__input-label-text {
    display: flex;
    margin-bottom: 0.5rem;
  }

  &__input {
    @extend .vol-input;

    width: 100%;

    &::placeholder {
      @include input-placeholder;
    }
  }

  &__grades-separator {
    font-size: 0.8375rem;
    margin: 2.45rem 0.75rem 0;

    #{$self}--medium & {
      margin: 2.45rem 0.9375rem 0;
    }
  }

  &__grade-input-toggle,
  &__add-teacher {
    margin-left: auto;
  }

  &__add-teacher {
    margin-left: auto;
  }

  .vol-person-search__input input {
    height: 1rem;
  }

  &__submit-button {
    width: 100%;

    #{$self}--medium & {
      width: auto;
    }
  }
}

.sdm-box {
  $self: &;

  position: relative;
  box-shadow: 0 0.3125rem 0.9375rem 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;

  &--medium {
    margin-bottom: 3rem;
  }

  &__modal-header {
    align-items: center;
  }

  &__close-button {
    &#{&} {
      opacity: 1;
      width: 2rem;
      height: 2rem;
      border: 0.125rem solid transparent;
      position: absolute;
      top: 0.75rem;
      right: 0.75rem;
    }

    &#{&}:hover {
      opacity: 0.5;
    }

    &#{&}:focus {
      border: 0.125rem solid #116eee;
    }
  }

  &__close-icon {
    width: 100%;
    height: 100%;
  }

  // Overrides
  .vol-modal__header {
    padding: 1rem 1rem 1.5rem;
  }

  .vol-modal__title {
    margin: 0;
    font-size: 1.3125rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #333333;
  }

  .vol-modal__body {
    padding: 0 1rem 1.5rem;
  }

  &--medium .vol-modal__header {
    padding: 1.5rem 2rem;
  }

  &--medium .vol-modal__title {
    font-size: 1.5rem;
  }

  &--medium .vol-modal__body {
    padding: 0 2rem 2rem;
  }
}

@import '~@scholastic/volume-core/src/mixins';
@import '../../../styles/functions';
@import '../../../styles/variables';

.sdm-step-confirm-subscription {
  $self: &;

  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  &--medium {
    flex-direction: row;
  }

  &__column {
    &--left {
      background-color: #f4f4f4;
      text-align: center;
      padding: 1rem 0;
      width: 100%;

      #{$self}--medium & {
        padding: 0;
        height: 100%;
        width: 40%;
      }
    }

    &--right {
      background-color: #ffffff;
      padding: 2rem 1.25rem 1rem;
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;

      #{$self}--medium & {
        display: block;
        padding: 2rem;
        height: 100%;
        width: 60%;
      }

      #{$self}--large & {
        padding: 4rem 7.5rem 4rem 2.5rem;
      }
    }
  }

  &__title {
    &#{&} {
      margin: 0 0 3rem 0;
      color: #333333;
      font-family: MuseoSans, sans-serif;
      font-size: 2rem;
      font-weight: 300;
      line-height: 1.09;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;
      display: none;

      #{$self}--medium & {
        margin: 0 0 1rem 0;
        text-align: left;
        display: block;
      }
    }
  }

  &__description {
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: normal;
    line-height: 1.38;
    color: #333333;
    margin-bottom: 2rem;

    #{$self}--medium & {
      margin: 0 0 2.75rem 0;
    }
  }

  &__form {
    flex: 1;
    display: flex;
    flex-direction: column;

    #{$self}--medium & {
      flex: 0 1 auto;
    }
  }

  &__form-group {
    #{$self}--medium & {
      margin-bottom: 2rem;
    }
  }

  &__label {
    &#{&}#{&} {
      margin-bottom: 1.5rem;
    }
  }

  &__label-text-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 0.625rem;
    font-size: 0.8125rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: normal;
    color: #333333;
  }

  .vol-searchable-dropdown__toggle,
  .vol-searchable-dropdown__input {
    height: 3.125rem;
  }

  .vol-searchable-dropdown__toggle {
    &#{&}#{&} {
      font-size: 1rem;
      font-weight: 300;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      color: #666666;
    }
  }

  &__input,
  .vol-searchable-dropdown__input {
    &::placeholder {
      font-family: $font-family-base;
      font-size: 1rem;
      font-weight: 300;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      color: #666666;
    }
  }

  .vol-searchable-dropdown__input {
    color: #333333;
    display: block;
    padding: 0.905rem 1rem;
    width: 100%;
    font-weight: 500;
    letter-spacing: normal;
    font-size: 1rem;
    border: 0.0625rem solid #919191;
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  &__home-school-button-wrapper {
    margin-top: 0.25rem;
    display: flex;
    justify-content: flex-end;
  }

  &__home-school-button {
    font-size: 0.8125rem;
  }

  &__feedback {
    &#{&}#{&} {
      display: flex;
    }
  }

  &__help {
    display: inline-flex;
    border-radius: 50%;
    transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    margin-left: 0.5rem;

    &:hover:not(:active):not(&--active) {
      background-color: #ededed;
    }

    &:focus:not(:active):not(&--active) {
      box-shadow: 0 0 0 0.125rem $blue inset;
      outline: none;
    }

    &:active,
    &--active {
      background-color: #e0e0e0;
      outline: none;
    }
  }

  &__help-popover {
    max-width: 20.5rem;

    .vol-popover__body {
      font-size: 0.6875rem;
      padding: 1rem 1.25rem;
    }
  }

  &__submit-button {
    margin-top: auto;
    width: 100%;

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.6;
    }

    #{$self}--medium & {
      margin-top: 0;
      width: 7.5rem;
    }
  }
}

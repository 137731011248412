.sdm-admin-programs {
  $self: &;

  display: block;
  width: 100%;

  &__header {
    margin-top: 2rem;

    #{$self}--medium & {
      margin-top: 4rem;
    }
  }
}

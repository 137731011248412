.sdm-class-details-edit-student-form {
  $self: &;

  &__cancel-button {
    &#{&} {
      font-size: 0.6875rem;
      letter-spacing: 0.09375rem;
      line-height: 1.09;
    }
  }

  &__submit-button {
    font-size: 0.6875rem;
    letter-spacing: 0.09375rem;
    line-height: 1.09;
    min-width: auto;
    margin-right: 1.5rem;

    &,
    &#{&}#{&}:focus {
      padding-left: 2.75rem;
      padding-right: 2.75rem;
    }

    #{$self}--medium &,
    #{$self}--medium &#{&}#{&}:focus {
      padding-left: 1.75rem;
      padding-right: 1.75rem;
    }
  }

  // Overrides
  .vol-modal__header {
    padding-top: 1.125rem;
    padding-bottom: 0.75rem;
  }
}

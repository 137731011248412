.sdm-class-details-page-header {
  $self: &;

  height: 11rem;
  margin-bottom: 3rem;

  &--medium {
    height: 6.625rem;
    margin-bottom: 1.25rem;
  }

  &--archived {
    margin-bottom: 3.6875rem;
  }

  &--medium#{&--archived} {
    margin-bottom: 3.4375rem;
  }

  .vol-page-header__content {
    padding-top: 1.5rem;
  }

  &--medium .vol-page-header__content {
    padding-top: 1.375rem;
  }

  &__page-header {
    display: flex;
  }

  &__column {
    display: flex;
    width: 100%;

    #{$self}--medium & {
      width: auto;
      flex: 1 1 auto;
    }

    &--left {
      flex-wrap: wrap;
      flex-direction: column;
      margin-bottom: 1.5rem;

      #{$self}--medium & {
        align-items: flex-start;
        padding-top: 0.625rem;
        margin-bottom: 0;
      }
    }

    &--right {
      #{$self}--medium & {
        justify-content: flex-end;
      }
    }
  }

  &__login-info-popover {
    &#{&} {
      position: static;
      transform: none;

      &.fade {
        z-index: 1;
      }
    }
  }

  &__manage-menu {
    &#{&} {
      margin-left: 1rem;
      position: static;
      transform: none;

      .fade {
        z-index: 1;
      }
    }

    &--no-margin {
      &#{&} {
        margin-left: 0;
      }
    }
  }

  &__icon {
    display: block;
  }

  &__school-name {
    &#{&} {
      flex: 0 1 100%;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.09375rem;
      color: #ffffff;
      font-size: 0.625rem;
      line-height: 1.2;
      margin-bottom: -1rem;

      #{$self}--medium & {
        font-size: 0.6875rem;
        line-height: 1.09;
        margin-bottom: 0.75rem;
      }
    }
  }

  &__class-name {
    &#{&} {
      margin: 0;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: #ffffff;
      font-size: 1.75rem;
      line-height: 1.14;
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      height: 4rem;

      #{$self}--medium & {
        font-size: 2rem;
        line-height: 1.09;
        margin-left: -0.125rem;
      }
    }
  }
}

@import '~@scholastic/volume-core/src/variables';

.vol-page-header {
  .vol-fab-menu {
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translateY(50%);
  }

  @each $breakpoint, $container---padding-x in $container---padding-x-map {
    &--#{$breakpoint} .vol-fab-menu {
      right: $container---padding-x;

      @if $breakpoint == huge {
        right: 0;
      }
    }
  }
}

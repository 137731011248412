.sdm-class-details-easy-login {
  $self: &;

  &__box {
    box-shadow: 0 0.3125rem 0.9375rem 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    #{$self}--medium & {
      margin: 2rem 0 3rem;
      flex-wrap: nowrap;
    }
  }

  &__title-with-logo-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__easy-login-logo {
    flex: 0 0 auto;
    width: 6.875rem;
    margin-bottom: 0.5rem;

    #{$self}--large & {
      width: auto;
      margin-bottom: 0;
    }
  }

  &__custom-bullet-list {
    flex: 1 1 100%;
    margin-bottom: 1.5rem;

    #{$self}--medium & {
      margin-bottom: 0;
    }
  }

  &__privacy-and-security {
    margin-bottom: 2rem;

    #{$self}--medium & {
      margin-bottom: 0;
    }
  }

  &__list-wrapper {
    margin: 0 2.5rem;
  }

  &__list-title {
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #333333;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.17;
    margin: 0 0.75rem 0 0;

    #{$self}--medium & {
      font-size: 2rem;
      font-weight: 300;
      line-height: 1.09;
      margin: 0 0 1.5rem 0;
    }
  }
}

.sdm-student-handouts {
  // ensure, that the content only renders when printing
  // @see https://github.com/gregnb/react-to-print#how-do-you-make-componenttoprint-show-only-while-printing
  display: none;

  &__print-button {
    background: none;
    padding: 0;
    border: 0;
    color: #63b4ff;
  }

  &__logo {
    text-align: center;
  }

  &__print-info {
    padding: 0 1.875rem;
    letter-spacing: normal;
  }

  &__section {
    font-family: 'Museo Sans W01_500', arial, sans-serif;
    padding: 0.625rem 0;
    font-size: 0.8125rem;
    letter-spacing: normal;

    h4 {
      font-size: 1.375rem;
      margin: 0;
      padding: 0 0 1.25rem 0;
      text-align: center;
    }

    ol li {
      padding: 0 0 1.25rem 0;
    }
  }

  &__credentials {
    padding-top: 1.25rem;
    letter-spacing: normal;
  }

  &__credentials-sub-div {
    display: inline-block;

    &--margin-right {
      margin-right: 2.5rem;
    }
  }

  &__student-info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 60%;
  }

  &__student-details {
    display: flex;
    flex-direction: column;
  }

  &__color-text {
    // !important is needed in order for the
    // color to be applied in the print mode
    color: #0082d6 !important;
  }

  &__bold-text {
    font-family: 'Museo Sans W01_700', arial, sans-serif;
  }

  &__page-break {
    page-break-after: always;
  }
}

/*
 * This file is used to override specific styles for volume components. Ideally, this file will not be required
 * once the UX project is complete and SDM is no longer using Bootstrap 3 alongside volume-core
 */

html {
  font-size: 16px;
}

body {
  /*
   * This would normally be included from _type.scss in volume-core, but we are not including those styles yet because
   * they conflict with Bootstrap 3
   */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  // This would normally come from _reboots.scss, but we are not using that to avoid conflicts with normalize
  font-family: $font-family-base;
}

.fade {
  &.in:not(.show) {
    // Fix conflicts with fading in for non-volume components
    opacity: 1;

    &.modal-backdrop {
      opacity: 0.5;
    }
  }

  &.show {
    opacity: 1;
  }
}

.collapse {
  &:not(.show) {
    display: block;
  }

  &:not(.in) {
    display: none;
  }
}

.vol-body--modal-open {
  // Kill the scroll on the body
  overflow: hidden !important;
}

// Reapply styles as nested to enforce styles until Bootstrap 3 is removed (no type conflicts)
.vol-modal {
  $self: &;

  .vol-modal__title {
    @extend .vol-modal__title;
  }

  &__header {
    #{$self}--extra-small & {
      padding-top: 3rem;
      padding-bottom: 1rem;
    }
  }

  &__title {
    #{$self}--extra-small & {
      font-size: 1.5rem;
    }
  }

  &__close {
    #{$self}--extra-small & {
      margin-top: -2.5rem;
    }
  }
}

// These fixes should be merged back into volume then removed from here

.vol-form {
  label.vol-form__label {
    font-size: 0.8125rem;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1.15;
  }
}

.vol-spinner > span {
  &.sr-only {
    position: absolute !important;
  }

  &:not(.sr-only) {
    position: relative;
  }
}

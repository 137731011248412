.sdm-student-badges {
  // ensure, that the content only renders when printing
  // @see https://github.com/gregnb/react-to-print#how-do-you-make-componenttoprint-show-only-while-printing
  display: none;

  &__print-button {
    background: none;
    padding: 0;
    border: 0;
    color: #63b4ff;
  }

  &__header {
    text-align: center;
    margin-bottom: 1.25rem;
  }

  &__text {
    font-size: 0.75rem;
  }

  &__content {
    border-top: 0.125rem dashed #6e6e6f;
  }

  &__student {
    display: inline-block;
    width: 50%;
    text-align: center;
    padding: 1.25rem;
    border: dashed #000;
    border-width: 0 0 0.125rem 0;
  }

  &__name {
    font-size: 1.125rem;
    padding: 0.3125rem 0;
    background-color: #000000 !important;
    color: #ffffff !important;
  }

  &__easy-login {
    margin: 0.625rem 0;
  }

  &__easy-login-section {
    margin-top: 0.9375rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &--content-center {
      justify-content: center;
    }
  }

  &__easy-login-column {
    text-align: left;
  }

  &__easy-login-text {
    max-width: 12.5rem;
    font-size: 0.625rem;
    margin-bottom: 0.9375rem;
  }

  &__easy-login-url {
    max-width: 12.5rem;
    font-size: 0.625rem;
    word-break: break-all;
    font-weight: bold;
  }

  &__easy-login-icon {
    display: inline-block;
    vertical-align: top;
    padding: 0 1.25rem;
  }

  &__easy-login-button {
    border: none;
    box-shadow: 0.4375rem 0.5625rem 0.125rem -0.125rem #c0c0c0;
    width: 4.375rem;
    height: 4.375rem;
    border-radius: 1.5625rem;
  }

  &__easy-login-svg {
    cursor: pointer;
    width: 4.375rem;
    height: 4.375rem;
    border-radius: 1.5625rem;
  }

  &__easy-login-icon-label {
    font-size: 0.6875rem;
    letter-spacing: 1px;
    font-weight: normal;
    display: block;
    margin: 0.625rem auto 0 auto;
    max-width: 4.375rem;
  }

  &__easy-login-text-snippet {
    font-size: 0.625rem;
    padding: 0.3125rem 0;
  }

  &__final-host-url {
    font-size: 0.625rem;
    word-break: break-all;
    font-weight: bold;
    padding: 0.3125rem 0;
  }

  &__easy-login-credentials {
    padding: 1.25rem;
  }

  &__easy-login-credentials-label {
    padding: 0.3125rem 0.75rem;
    border: solid #000000;
    border-width: 0.125rem 0.125rem 0 0.125rem;
    border-radius: 0.3125rem 0.3125rem 0 0;
    background-color: #000000 !important;
    color: #ffffff !important;
    width: 12.5rem;
    margin: auto;
  }

  &__easy-login-credentials-text {
    padding: 0.3125rem 0.75rem;
    border: 0.125rem solid #000000;
    border-radius: 0 0 0.3125rem 0.3125rem;
    width: 12.5rem;
    margin: auto;
  }
}

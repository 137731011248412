@import '~@scholastic/volume-core/src/mixins';
@import '../../styles/functions';
@import '../../styles/variables';

.sdm-redeem-access-modal {
  $self: &;

  background: #ffffff;

  &--small {
    .vol-modal__content {
      height: 26.25rem;
    }
  }

  &--medium,
  &--large-up {
    .vol-modal__content {
      height: 37.5rem;
    }
  }

  &__header {
    background: #ffffff;
    position: absolute;
    top: 0;
    right: 0;

    .vol-modal__close {
      z-index: 1;
    }
  }

  &__body {
    background: #ffffff;
    display: flex;
    padding: 0;

    #{$self}--extra-small-down & {
      flex-direction: column;
    }

    #{$self}--small &,
    #{$self}--medium &,
    #{$self}--large-up & {
      flex-direction: row;
    }
  }

  &__logo {
    height: 5.75rem;

    #{$self}--small &,
    #{$self}--medium & {
      height: auto;
      width: 80%;
      margin-top: 3.5rem;
    }

    #{$self}--large-up & {
      height: auto;
      width: 16.875rem;
      margin-top: 5.4375rem;
    }
  }
}

@import '../../styles/functions';
@import '../../styles/variables';
@import '~@scholastic/volume-core/src/mixins';
@import '~@scholastic/volume-core/src/pure/control';
@import '~@scholastic/volume-core/src/pure/input';

@mixin input-placeholder {
  font-family: $font-family-sans-serif;
  color: $gray-600;
  font-size: 1rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal !important;
  line-height: 1.19;
  letter-spacing: normal !important;
}

.sdm-input {
  &__input-label {
    font-family: $font-family-sans-serif;
    color: $gray-900;
    font-size: 0.8125rem !important; // TODO: Remove the important flag after volume form cleanup
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15 !important; // TODO: Remove the important flag after volume form cleanup
    letter-spacing: normal;
  }

  &__input-label-text {
    display: flex;
    margin-bottom: 0.5rem;
  }

  &__input-field {
    @extend .vol-input;

    width: 100%;

    &::placeholder {
      @include input-placeholder;
    }
  }
}

@import '~@scholastic/volume-core/src/smart/person-card/person-card.module';
@import '~@scholastic/volume-core/src/variables';

$sdm-admin-program-details__teacher---margin-y: 1rem;
$sdm-admin-program-details__teacher---margin-x: 1.40625rem;

.sdm-admin-program-details {
  $self: &;

  &__fab-menu {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translateY(50%);
  }

  &__header {
    margin-top: 1.5rem;
    margin-bottom: 0.875rem;

    #{$self}--medium & {
      margin-top: 2.5rem;
      margin-bottom: 1rem;
    }
  }

  &__option-wrapper {
    display: flex;
    align-items: center;
  }

  &__option-label {
    margin-right: 0.5rem;
  }

  &__teachers {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 0 4rem;

    #{$self}--medium & {
      margin: 0 #{-$sdm-admin-program-details__teacher---margin-x};
    }

    &--hidden {
      display: none;
    }
  }

  &__teacher {
    flex: 1 1 100%;
    max-width: 100%;
    margin: 0.125rem 0;
    height: $person-card--layout-horizontal---height;

    #{$self}--medium & {
      flex: 0 0 auto;
      margin: $sdm-admin-program-details__teacher---margin-y
        $sdm-admin-program-details__teacher---margin-x;
      width: $person-card--layout-medium---width;
      height: $person-card--layout-medium---height;
    }

    &--placeholder {
      &#{&} {
        height: 0;
        margin: 0;

        #{$self}--medium & {
          margin: 0 $sdm-admin-program-details__teacher---margin-x;
        }
      }
    }

    .vol-action-card__heading {
      white-space: nowrap;
    }
  }

  &__program-hero-metrics-container {
    padding-top: 0.625rem;

    &--hidden {
      display: none;
    }

    .vol-program-hero-metrics {
      flex-direction: column;
    }

    .vol-metric__wrapper {
      margin-bottom: 1.5rem;
    }
  }
}

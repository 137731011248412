.sdm-login-info-popover {
  $self: &;

  display: flex;
  flex-direction: column;
  padding: 1.125rem 1.25rem;

  &--medium {
    flex-direction: row;
    padding: 1.125rem 0.75rem 1.75rem;
  }

  &__icon {
    display: block;
  }

  &__card {
    display: flex;
    align-items: center;
    margin: 0.75rem 0;

    #{$self}--medium & {
      width: 8.125rem;
      flex-direction: column;
      margin: 0 0.75rem;
    }
  }

  &__logo-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    width: 6.3125rem;
    height: 4.25rem;

    #{$self}--medium & {
      width: 100%;
      height: 5.4375rem;
      margin-bottom: 0.5rem;
    }
  }

  &__title {
    &#{&} {
      font-size: 1rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      color: #ffffff;
      margin-bottom: 0.25rem;
    }

    #{$self}--medium &#{&} {
      margin: 0 0 0.5rem 0;
      font-size: 1.125rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
      color: #ffffff;
    }
  }

  &__text {
    font-size: 0.8125rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.23;
    letter-spacing: normal;
    color: #ffffff;
    margin: 0 0 0.25rem 0;

    #{$self}--medium & {
      margin: 0;
    }
  }
}

// overrides
.sdm-login-info-popover-override {
  .vol-popover {
    margin-top: 0.3125rem;
    max-width: none;
  }
}

@import '~@scholastic/volume-core/src/mixins';
@import '../../../styles/functions';
@import '../../../styles/variables';

.sdm-step-agreement {
  $self: &;

  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  &--medium {
    flex-direction: row;
  }

  &__column {
    &--left {
      background-color: #f4f4f4;
      text-align: center;
      padding: 1rem 0;
      width: 100%;

      #{$self}--medium & {
        padding: 0;
        height: 100%;
        width: 40%;
      }
    }

    &--right {
      background-color: #ffffff;
      padding: 2rem 1.25rem 1rem;
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;

      #{$self}--medium & {
        display: block;
        padding: 2rem;
        height: 100%;
        width: 60%;
      }

      #{$self}--large & {
        padding: 4rem 7.5rem 4rem 2.5rem;
      }
    }
  }

  &__title {
    &#{&} {
      margin: 0 0 2rem 0;
      color: #333333;
      font-family: MuseoSans, sans-serif;
      font-size: 2rem;
      font-weight: 300;
      line-height: 1.09;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;

      #{$self}--medium & {
        text-align: left;
      }
    }
  }

  &__form {
    flex: 1;
    display: flex;
    flex-direction: column;

    #{$self}--medium & {
      flex: 0 1 auto;
    }
  }

  &__form-group {
    margin-bottom: 2rem;

    &--hidden {
      display: none;
    }
  }

  &__checkbox-wrapper {
    display: flex;
  }

  &__checkbox {
    &#{&}#{&} {
      flex: 0 0 1.25rem;
      margin-right: 0.75rem;
    }
  }

  &__label-text {
    flex: 1 1 auto;
    font-size: 1rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #333333;
  }

  &__link {
    font-size: 1rem;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #116eee;
  }

  &__feedback {
    flex: 1 1 100%;

    &#{&}#{&} {
      display: flex;
    }
  }

  &__button-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;

    #{$self}--medium & {
      justify-content: left;
      margin-top: 1rem;
    }
  }

  &__submit-button {
    margin-right: 1.5rem;

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.6;
    }

    #{$self}--medium & {
      margin-top: 0;
      width: 7.5rem;
    }
  }

  &__back-button {
    &#{&}#{&}#{&} {
      font-size: 0.6875rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.09375rem;
      text-transform: uppercase;
    }
  }
}

@import '../../styles/functions';
@import '../../styles/variables';
@import '~@scholastic/volume-core/src/mixins';

.sdm-program-card-list {
  $self: &;

  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;

  &__inner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 100%;

    #{$self}--extra-small:not(#{$self}--small) & {
      max-width: 17.5rem;

      .vol-card {
        margin: 1.5rem 0 0 0.75rem;

        &:first-child,
        &:nth-child(2n + 1) {
          margin-left: 0;
        }
      }
    }

    #{$self}--small:not(#{$self}--medium) & {
      max-width: 30rem;

      .vol-card {
        margin: 1.5rem 0 0 2.4375rem;

        &:first-child,
        &:nth-child(3n + 1) {
          margin-left: 0;
        }
      }
    }

    #{$self}--medium:not(#{$self}--large) & {
      max-width: 40rem;

      .vol-card {
        margin: 2rem 0 0 1.1875rem;

        &:first-child,
        &:nth-child(3n + 1) {
          margin-left: 0;
        }
      }
    }

    #{$self}--large:not(#{$self}--extra-large) & {
      max-width: 42.5rem;

      .vol-card {
        margin: 2rem 0 0 2.5rem;

        &:first-child,
        &:nth-child(3n + 1) {
          margin-left: 0;
        }
      }
    }

    #{$self}--extra-large:not(#{$self}--huge) & {
      max-width: 57.5rem;

      .vol-card {
        margin: 2rem 0 0 2.5rem;

        &:first-child,
        &:nth-child(4n + 1) {
          margin-left: 0;
        }
      }
    }

    #{$self}--huge & {
      max-width: $container--huge---max-width;

      .vol-card {
        margin: 2rem 0 0 3.75rem;

        &:first-child,
        &:nth-child(5n + 1) {
          margin-left: 0;
        }
      }
    }
  }

  &__class-mag-wrapper {
    margin-top: 1.5rem;
    width: 100%;

    .vol-class-pass-code-card--small {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__header {
    width: 100%;

    @include transition;

    .vol-section-header,
    .vol-section-header--extra-small {
      margin-top: $program-card-list__header--extra-small-down---margin-top;
    }

    .vol-section-header--medium {
      margin-top: $program-card-list__header--small-up---margin-top;
    }
  }
}

@import '~@scholastic/volume-core/src/smart/class-card/class-card.module';
@import '~@scholastic/volume-core/src/smart/page-header/page-header.module';
@import '../../styles/variables';

$teacher-classes__class-card---margin-x: 1.25rem !default;
$teacher-classes__class-card---margin-y: 1rem !default;

.sdm-teacher-classes__page-header {
  .vol-page-header__content {
    justify-content: center;
  }

  &.vol-page-header--medium .vol-page-header__content {
    justify-content: flex-start;
  }
}

.sdm-teacher-classes {
  $self: &;

  padding: 2rem 0;

  &--medium {
    padding: 4rem 0;
  }

  &__container {
    margin: 0 auto;
  }

  &__container-outer {
    display: flex;
    justify-content: center;
  }

  &__container-inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin: 0 -$teacher-classes__class-card---margin-x;
  }

  &__section-header {
    margin-bottom: 1rem;
  }

  &__class-card {
    display: flex;
    margin: $teacher-classes__class-card---margin-y $teacher-classes__class-card---margin-x;
  }

  // these styles are needed to override the default
  // html-tag styles that are set by the dp-portal
  .vol-class-card {
    &__description,
    &__teachers,
    &__teacher-name,
    &__teacher-count,
    &__member-count-text,
    &__grades-inner,
    &__avatar {
      letter-spacing: initial;
    }
  }

  // these styles are needed to override the default
  // html-tag styles that are set by the dp-portal
  .vol-class-card__title {
    font-weight: 500;
    line-height: 1.25;
    font-size: 0.6875rem;
    margin: 0 0 0.4375rem 0;
  }

  // these styles are needed to override the default
  // html-tag styles that are set by the dp-portal
  &--medium .vol-class-card__title {
    font-size: 0.9375rem;
    margin: 0 0 0.875rem 0;
  }

  &__class-card-placeholder {
    margin: 0 $teacher-classes__class-card---margin-x;
    width: $class-card--small---width;

    #{$self}--medium & {
      width: $class-card---width;
    }
  }

  &__options {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;

    // make sure option menus always renders over cards
    z-index: 1;
  }

  &__filters {
    margin-left: auto;

    &-message {
      max-width: 37.875rem;
      margin: 3rem auto 0;
    }

    &-label {
      font-size: 0.6875rem;
      line-height: 1.09;
      letter-spacing: 0.09375rem;
      margin-right: 1rem;
    }

    &-toggle {
      display: inline-flex;
      flex-direction: row;
      align-items: center;

      background-color: transparent;
      border: 0;
      height: auto;

      &:not(:disabled):focus,
      &:not(:disabled):focus-visible {
        outline: none;
        box-shadow: 0 0 0 2px $blue inset;
      }

      &:not(#{$self}--medium &) {
        font-size: 0.6875rem;
        font-weight: 500;
        line-height: 1.09;
        letter-spacing: 1.5px;

        .vol-badge {
          margin-left: 0.25rem;
        }
      }

      #{$self}--medium & {
        font-size: 11px;
        line-height: 1.09;

        padding: 0 0 0 0.25rem;

        .vol-caret {
          transform: scale(0.7271);
          transition: $transition-base;
          margin-left: 0.5rem;

          &--show {
            transform: scale(0.7271) rotate(180deg);
          }
        }
      }
    }

    &-popover {
      .vol-popover {
        margin-top: 0;
      }
    }

    &-modal.vol-modal__dialog {
      margin: 0;
      height: 100%;

      .vol-modal__content {
        height: 100%;
        background-color: $gray-900;
        color: $white;

        .vol-modal__header {
          padding: 1.375rem 1.25rem 2.5rem 1.25rem;

          .vol-modal__title {
            font-size: 0.6875rem;
            font-weight: 500;
            line-height: 1.09;
            letter-spacing: 1.5px;

            .vol-badge {
              margin-left: 0.25rem;
            }
          }

          .vol-modal__close {
            margin: 0;
            svg path {
              fill: $white;
            }
          }
        }

        .vol-modal__body.sdm-teacher-classes__filters-menu {
          width: 100%;
          padding: 0 1.25rem;

          .vol-button.sdm-teacher-classes__filters-calendars-toggle .vol-caret {
            margin-right: 0.25rem;
          }
        }
      }
    }

    &-menu {
      width: 16.25rem;
      height: auto;
      max-height: none;
      padding: 1.75rem;
      color: $white;
      transition: $transition-base;

      hr {
        border-color: $gray-450;
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;
      }
    }

    &-calendars {
      &-toggle.vol-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-size: 0.8125rem;
        font-weight: bold;
        line-height: 1.15;
        color: $white;

        .vol-caret {
          margin-left: auto;
          transform: scale(0.7271);
          transition: transform 0.2s ease-in;

          &--show {
            transform: scale(0.7271) rotate(180deg);
          }

          path {
            fill: $gray-450;
          }
        }
      }

      &-value,
      &-option {
        font-size: 0.8125rem;
        line-height: 1.15;
      }

      &-value,
      &-option--selected {
        font-weight: bold;
        color: $white;
      }

      &-value {
        margin-top: 0.6875rem;

        &::before {
          content: '';
          display: inline-block;
          width: 0.5rem;
          height: 0.5rem;
          margin-right: 0.75rem;
          background-color: $white;
          border-radius: 50%;
        }
      }

      &-options {
        position: relative;
        margin-top: 0.6875rem;
        padding-left: 1.5rem;
        max-height: 9.6875rem;
        overflow-y: scroll;

        &:after {
          content: '';
          position: sticky;
          display: block;
          width: 100%;
          height: 1rem;
          bottom: 0;
          left: 0;

          background-image: linear-gradient(to top, $gray-900 0%, rgba(51, 51, 51, 0) 100%);
          background-size: 100% 1rem;
          background-repeat: no-repeat;
          background-position: bottom;
          background-attachment: scroll;
        }
      }

      &-option {
        width: 100%;
        list-style: none;
        padding: 0.625rem 0;
        position: relative;
        display: block;

        appearance: none;
        background: transparent;
        border: none;
        margin: 0;
        text-align: left;

        &:hover,
        &:focus {
          cursor: pointer;
          font-weight: bold;
        }

        &:focus,
        &:focus-visible {
          outline: none;
          text-decoration: underline;
        }

        &:not(&--selected) {
          color: $gray-450;
        }

        &--selected {
          &::before {
            content: '';
            position: absolute;
            display: flex;
            width: 0.5rem;
            height: 0.5rem;
            left: -1.25rem;
            top: 0.75rem;
            background-color: $white;
            border-radius: 50%;
          }
        }
      }
    }

    &-archived {
      &-switch {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &-label {
        font-size: 0.8125rem;
        font-weight: bold;
        line-height: 1.15;
        color: $white;

        margin: 0 auto 0 0;
      }

      &-status {
        margin-left: 0.625rem;
        font-size: 0.8125rem;
        font-weight: 500;
        line-height: 1.23;
        color: $white;

        &--active {
          color: $success;
        }
      }
    }
  }
}

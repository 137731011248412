.sdm-student-details-page-header {
  $self: &;

  margin-bottom: 3rem;
  height: 13.125rem;

  &--medium {
    height: 6.625rem;
    margin-bottom: 1.25rem;
  }

  &--archived {
    margin-bottom: 3.6875rem;
  }

  &--medium#{&--archived} {
    margin-bottom: 3.4375rem;
  }

  .vol-page-header__content {
    padding-top: 1rem;

    #{$self}--medium & {
      padding-top: 1.5rem;
    }
  }

  &--medium .vol-page-header__content {
    padding-top: 1.375rem;
  }

  &__page-header {
    display: flex;
  }

  &__column {
    display: flex;
    width: 100%;

    #{$self}--medium & {
      width: auto;
      flex: 1 1 auto;
    }

    &--left {
      align-items: center;
      margin-bottom: 1rem;
      justify-content: center;

      #{$self}--medium & {
        max-width: 50%;
        align-items: center;
        padding-top: 0.625rem;
        margin-bottom: 0;
        justify-content: flex-start;
      }
    }

    &--right {
      justify-content: center;

      #{$self}--medium & {
        justify-content: flex-end;
      }
    }
  }

  &__avatar-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;

    #{$self}--medium & {
      justify-content: flex-start;
      flex-wrap: nowrap;
    }
  }

  &__login-info-popover {
    &#{&} {
      position: static;
      transform: none;

      &.fade {
        z-index: 1;
      }
    }
  }

  &__manage-menu {
    &#{&} {
      margin-left: 1rem;
      position: static;
      transform: none;

      .fade {
        z-index: 1;
      }
    }

    &--no-margin {
      &#{&} {
        margin-left: 0;
      }
    }
  }

  &__icon {
    display: block;
  }

  &__avatar {
    flex: 0 1 100%;
    margin-bottom: 0.625rem;

    #{$self}--medium & {
      flex: 0 1 auto;
      margin-right: 1.5rem;
      margin-bottom: 0;
    }
  }

  &__school-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    #{$self}--medium & {
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  &__school-name {
    &#{&} {
      display: flex;
      align-items: center;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.09375rem;
      color: #ffffff;
      font-size: 0.625rem;
      line-height: 1.2;
      margin-bottom: 0.3125rem;
      width: 100%;

      #{$self}--medium & {
        font-size: 0.6875rem;
        line-height: 1.09;
        margin-bottom: 0.75rem;
      }
    }
  }

  &__school-name-inner {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;

    #{$self}--medium & {
      text-align: left;
    }
  }

  &__student-name {
    &#{&} {
      margin: 0;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: #ffffff;
      font-size: 1.75rem;
      line-height: 1.14;
      max-width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-left: -0.125rem;

      #{$self}--medium & {
        font-size: 2rem;
        line-height: 1.09;
      }
    }
  }
}

@import '~@scholastic/volume-core/src/smart/person-card/person-card.module';
@import '~@scholastic/volume-core/src/variables';

$sdm-class-details-students__student---margin-x: 0.375rem;
$sdm-class-details-students__student--medium---margin-x: 1.40625rem;
$sdm-class-details-student-form-wrapper__student---margin-y: 0.75rem;
$sdm-class-details-student-form-wrapper__student--medium---margin-y: 1rem;
$sdm-class-details-student-form-wrapper__student--edit-mode---margin-bottom: 0.5rem;

.sdm-class-details-students {
  $self: &;

  &--edit-mode {
    display: flex;
    flex-direction: column;
  }

  &--read-mode {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 #{-$sdm-class-details-students__student---margin-x};
  }

  &--medium#{&--read-mode} {
    margin: 0 #{-$sdm-class-details-students__student--medium---margin-x};
  }

  &__student {
    #{$self}--edit-mode & {
      margin-bottom: $sdm-class-details-student-form-wrapper__student--edit-mode---margin-bottom;
    }

    #{$self}--read-mode &#{&}#{&} {
      min-width: auto;
      flex: 0 0 auto;
      margin: $sdm-class-details-student-form-wrapper__student---margin-y
        $sdm-class-details-students__student---margin-x;
      width: $person-card--layout-small---width;
      height: $person-card--layout-small---height;

      .vol-action-card__heading {
        white-space: nowrap;
      }
    }

    #{$self}--medium#{$self}--read-mode &#{&}#{&} {
      margin: $sdm-class-details-student-form-wrapper__student--medium---margin-y
        $sdm-class-details-students__student--medium---margin-x;
      width: $person-card--layout-medium---width;
      height: $person-card--layout-medium---height;
    }
  }

  &__student--placeholder {
    #{$self}--read-mode &#{&}#{&} {
      height: 0;
      margin: 0 $sdm-class-details-students__student---margin-x;
      width: $person-card--layout-small---width;
    }

    #{$self}--medium#{$self}--read-mode &#{&}#{&} {
      height: 0;
      margin: 0 $sdm-class-details-students__student--medium---margin-x;
      width: $person-card--layout-medium---width;
    }
  }

  &__edit-student-form {
    margin-bottom: 0.5rem;
  }

  &__banner-message-wrapper {
    margin-top: 3rem - $sdm-class-details-student-form-wrapper__student--edit-mode---margin-bottom;
    display: flex;
    justify-content: center;
  }

  &__banner-message {
    display: inline-flex;
  }
}

@import '~@scholastic/volume-core/src/mixins';
@import '../../../styles/functions';
@import '../../../styles/variables';

.sdm-step-final {
  $self: &;

  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  &--medium {
    flex-direction: row;
  }

  &__column {
    &--left {
      background-color: #f4f4f4;
      text-align: center;
      padding: 1rem 0;
      width: 100%;

      #{$self}--medium & {
        padding: 0;
        height: 100%;
        width: 40%;
      }
    }

    &--right {
      background-color: #ffffff;
      padding: 2rem 1.25rem 1rem;
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 100%;

      #{$self}--medium & {
        display: block;
        padding: 2rem;
        height: 100%;
        width: 60%;
      }

      #{$self}--large & {
        padding: 4rem 7.5rem 4rem 2.5rem;
      }
    }
  }

  &__title {
    &#{&} {
      margin: 0 0 1rem 0;
      color: #333333;
      font-family: MuseoSans, sans-serif;
      font-size: 2rem;
      font-weight: 300;
      line-height: 1.09;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: center;

      #{$self}--medium & {
        text-align: left;
      }
    }
  }

  &__description {
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: normal;
    line-height: 1.38;
    color: #333333;
    margin-bottom: 2rem;

    #{$self}--medium & {
      margin: 0 0 2rem 0;
    }
  }

  &__class-mag {
    margin: 0 auto 3rem;

    #{$self}--medium & {
      margin: 0 0 3rem 0;
    }
  }

  &__done-button {
    margin-top: auto;

    #{$self}--medium & {
      margin-top: 0;
      width: 7.5rem;
    }
  }
}

@import '~@scholastic/volume-core/src/smart/person-card/person-card.module';
@import '~@scholastic/volume-core/src/variables';

.sdm-class-details {
  $self: &;

  &__metrics {
    display: flex;

    #{$self}:not(#{$self}--medium) & {
      flex-direction: column;

      .vol-metric__wrapper {
        padding: 0.75rem 0;
      }
    }

    #{$self}--medium & {
      margin: 1.25rem 0 3rem 0;
      padding-right: 4rem;
    }
  }

  &__metric {
    min-width: 7.5rem;
    margin: 0 0.5rem 0 0;
    padding-left: 0.5rem;
    border-left: 1px solid #666666;

    .vol-metric__term,
    .vol-metric__definition {
      color: #333333;
    }

    .vol-metric__term {
      justify-content: flex-start;
      margin: 0;
    }

    .vol-metric__definition {
      text-align: left;
      margin-bottom: 0.25rem;
    }
  }

  &__header {
    margin-top: 1.5rem;
    margin-bottom: 0.875rem;

    #{$self}--medium & {
      margin-top: 2.5rem;
      margin-bottom: 1rem;
    }

    .vol-select-list__menu-list {
      max-height: none;
    }
  }

  &__banner-message {
    margin: 3rem auto 0;

    #{$self}--medium & {
      width: 37.5rem;

      .vol-banner-message__main,
      .vol-banner-message__action {
        white-space: nowrap;
      }
    }

    .vol-banner-message__action {
      font-size: 0.8125rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.15;
      letter-spacing: normal;
      color: #116eee;
    }
  }
}

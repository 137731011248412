@import '../../styles/functions';
@import '../../styles/variables';
@import '~@scholastic/volume-core/src/mixins';
@import '~@scholastic/volume-core/src/pure/control';
@import '~@scholastic/volume-core/src/pure/input';

@mixin select($root, $marginBottom: true) {
  &#{&}#{&} {
    max-width: 100%;
    flex: 0 1 100%;

    @if ($marginBottom) {
      margin-bottom: 1rem;
    }
  }

  #{$root}--medium &#{&}#{&} {
    flex: 0 1 auto;
    margin-bottom: 0;
  }
}

@mixin input-placeholder {
  font-family: $font-family-sans-serif;
  color: $gray-600;
  font-size: 1rem;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal !important;
  line-height: 1.19;
  letter-spacing: normal !important;
}

.sdm-student-details {
  $self: &;

  display: block;
  width: 100%;

  &__banner {
    height: 6.625rem;
  }

  &__dialog {
    box-shadow: 0 0.3125rem 0.9375rem 0 rgba(0, 0, 0, 0.1);
    margin-top: 3.625rem;

    // Overrides
    .vol-modal__header {
      padding: 1rem 1rem 1.5rem;
    }

    .vol-modal__title {
      margin: 0;
      font-size: 1.3125rem;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
      color: #333333;
    }

    .vol-modal__body {
      padding: 0 1rem 1.5rem;
    }
  }

  #{$self}--medium &__dialog .vol-modal__header {
    padding: 1.5rem 2rem;
  }

  #{$self}--medium &__dialog .vol-modal__title {
    font-size: 1.5rem;
  }

  #{$self}--medium &__dialog .vol-modal__body {
    padding: 0 2rem 1.5rem;
  }

  &__modal-header {
    align-items: center;
  }

  &__modal-button {
    &#{&} {
      margin-top: -0.625rem;
      margin-right: -0.625rem;
      opacity: 1;
      width: 1.75rem;
      height: 1.75rem;
      border: 0.125rem solid transparent;
    }

    &#{&}:hover {
      opacity: 0.5;
    }

    &#{&}:focus {
      border: 0.125rem solid #116eee;
    }
  }

  &__modal-button-inner {
    display: flex;
  }

  &__modal-icon {
    width: 100%;
    height: 100%;
  }

  &__section {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    #{$self}--medium & {
      flex-wrap: nowrap;
      margin-left: -0.90625rem;
      margin-right: -0.90625rem;
      margin-bottom: 0.875rem;
    }

    &--no-margin {
      margin-bottom: 0;
    }
  }

  &__select {
    font-family: $font-family-sans-serif;
    border-left: $border-divider-vertical;
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 0.5rem;
    padding-right: 1rem;

    // Overrides
    .vol-select-list__control {
      padding: 0;
    }

    .vol-select-list__menu {
      width: auto;
      min-width: 11.875rem;
      max-width: 15rem;
    }

    .vol-select-list__option {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  // Overrides
  #{$self}--small &__select .vol-select-list__menu {
    max-width: 17.5rem;
  }

  &__select-label {
    font-family: $font-family-sans-serif;
    color: $gray-900;
    font-size: 0.6875rem !important; // TODO: Remove the important flag after volume form cleanup
    font-weight: 500;
    letter-spacing: normal;
    line-height: 1.09 !important; // TODO: Remove the important flag after volume form cleanup
  }

  &__select-label-input {
    max-width: 100%;
  }

  &__metric {
    border-left: $border-divider-vertical;
    margin: 0;
    padding-right: 1.5rem;
    padding-left: 0.5rem;
  }

  &__metric-heading {
    color: $gray-900;
    font-weight: $headings-font-weight;
    align-items: flex-start;
    justify-content: flex-start;
    height: 0.75rem;
    margin-bottom: 0.25rem;
    font-size: 0.6875rem;
    line-height: 1.09;
    text-align: left;
  }

  &__metric-content {
    color: $gray-900;
    margin: 0;
    align-items: flex-start;
    justify-content: flex-start;
    height: 1.1875rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.19;
  }

  &__form-group {
    &#{&}#{&} {
      flex: 0 1 100%;
      margin-bottom: 0.375rem;
    }

    #{$self}--medium &#{&}#{&} {
      flex: 0 1 33.33%;
      margin: 0 0.90625rem;
    }

    &#{&}#{&}#{&}--half {
      flex: 0 1 50%;

      &:first-child {
        margin-left: 0;
        margin-right: 0.5rem;
      }

      &:last-child {
        margin-left: 0.5rem;
        margin-right: 0;
      }
    }

    &#{&}#{&}#{&} {
      .vol-input {
        border-color: #919191;
        opacity: 1;
      }

      .vol-input:focus {
        border-color: #116eee;
      }

      .vol-select-control__control--is-disabled {
        opacity: 1;
      }
    }
  }

  &__form-group-wrapper {
    display: flex;
    align-items: flex-end;
    flex: 0 1 100%;

    #{$self}--medium & {
      flex: 0 1 33.33%;
      margin: 0 0.90625rem;
    }

    // Overrides
    .vol-searchable-dropdown__toggle,
    .vol-searchable-dropdown__input {
      height: 3.125rem;
      border-radius: 0.3125rem;
    }
  }

  .vol-form__group {
    margin-bottom: 0;
  }

  &__fa-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 0.75rem;
  }

  &__fa {
    font-size: 1.5rem;
  }

  .vol-select-with-images__menu-list &__fa-wrapper {
    width: 2rem;
    height: 2rem;
    border-radius: 0.6rem;
  }

  .vol-select-with-images__menu-list &__fa {
    font-size: 1.2rem;
  }

  &__input-label {
    font-family: $font-family-sans-serif;
    color: $gray-900;
    font-size: 0.8125rem !important; // TODO: Remove the important flag after volume form cleanup
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15 !important; // TODO: Remove the important flag after volume form cleanup
    letter-spacing: normal;
  }

  &__input-label-text {
    margin-bottom: 0.5rem;
    display: flex;
    // these styles are needed in order to prevent 2-line
    // labels from causing the form fields to jump around
    align-items: flex-end;
    height: 0.875rem;
  }

  &__input {
    @extend .vol-input;

    width: 100%;

    &::placeholder {
      @include input-placeholder;
    }
  }

  &__grade-input-toggle,
  &__add-teacher {
    margin-left: auto;
  }

  &__add-teacher {
    margin-left: auto;
  }

  .vol-person-search__input input {
    height: 1rem;
  }

  &__separator {
    &#{&} {
      margin: 0 0 1.5rem 0;
      background-color: #b5b5b5;
    }

    #{$self}--medium &#{&} {
      margin: 1.5rem 0;
    }
  }

  &__sub-title {
    &#{&} {
      font-size: 1.125rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.17;
      letter-spacing: normal;
      color: #333333;
      margin: 1.5rem 0;
    }
  }

  &__controls {
    margin-top: 1.125rem;
  }

  &__submit-button {
    width: 100%;

    #{$self}--medium & {
      width: auto;
    }
  }
}

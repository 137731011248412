.sdm-login-section {
  $self: &;

  border-top: 1px solid #b5b5b5;
  padding: 2.125rem 0.75rem 2rem 0.75rem;

  &--medium {
    display: flex;
    align-items: flex-start;
    padding: 2rem 2.5rem 2rem 3rem;
  }

  &__title {
    flex: 0 0 12.5rem;
    white-space: nowrap;
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #333333;
    margin-bottom: 1.5rem;

    #{$self}--medium & {
      margin-right: 1rem;
    }

    #{$self}--large & {
      margin-right: 2rem;
    }

    #{$self}--extra-large & {
      margin-right: 5.125rem;
    }
  }

  &__title-icon {
    margin-right: 0.5rem;
  }

  &__description {
    display: flex;
    flex-wrap: wrap;
  }

  &__description-text {
    flex: 1 1 100%;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #333333;
    font-size: 0.875rem;
    line-height: 1.43;
    margin-bottom: 2rem;

    #{$self}--medium & {
      font-size: 1rem;
      line-height: 1.38;
      margin-bottom: 1.5rem;
    }
  }

  &__wrapper {
    flex: 1 1 100%;

    #{$self}--medium & {
      display: flex;
    }
  }

  &__copy-link {
    flex: 1 1 auto;
    margin-bottom: 1.5rem;

    #{$self}--medium & {
      margin-bottom: 0;
    }

    // overrides
    .vol-copy-link__label.vol-copy-link__label {
      display: block;
    }

    .vol-copy-link__input.vol-copy-link__input {
      width: 100%;
      border: solid 1px #919191;
      opacity: 1;
    }
  }

  &__separator {
    // stylelint-disable-next-line
    width: 1px;
    background-color: #b5b5b5;
    margin: 0 2.5rem;
    display: none;

    #{$self}--medium & {
      display: block;
    }
  }

  &__qr-code {
    // `!important` is needed to override an inline style
    width: 100% !important;

    #{$self}--medium & {
      width: auto !important;
    }

    .vol-qr-code__qr-code {
      display: flex;
      justify-content: center;
    }
  }
}
